import { Component, OnInit } from '@angular/core';
import { ReportService } from '@shared/services';
import { FilterValues, FormModel, InsightDocumentList, InsightDocumentListItem, RequestPageParams } from '@shared/models';
import { isUndefined } from 'lodash/index';
import { FetchData } from '@rocketfinancialcorp/rocket-ui/table';
import { takeUntil } from 'rxjs/operators';
import { ErrorUtils, groupReportByDate, activeFilters } from '@shared/utils';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { FormGroup } from '@angular/forms';
import { StatementFormFields } from '@modules/insights/components/insight-statement-page/statement-form-field';

@Component({
  selector: 'app-insight-statement-page',
  templateUrl: './insight-statement-page.component.html',
  styleUrls: ['./insight-statement-page.component.scss'],
})
export class InsightStatementPageComponent implements OnInit {
  statements!: InsightDocumentListItem[];

  loading!: boolean;

  totalElements = 0;

  totalPages = 0;

  activeFilters: FilterValues = {};

  statementFormFields = StatementFormFields;

  statementFilterForm = new FormGroup({});

  formModel: FormModel<{
    dateRange: string;
  }> = {};

  get activePage(): number {
    const routePage = this.activatedRoute?.snapshot?.queryParams?.page;
    return routePage ? parseInt(routePage, 10) : 0;
  }

  private destroy$ = new Subject<void>();

  constructor(public reportService: ReportService, private router: Router, private activatedRoute: ActivatedRoute) {}

  ngOnInit() {
    this.activeFilters = activeFilters({ dateRange: 'THIS_WEEK' });
    this.getInsightStatements({ page: 0 });
  }

  updateQueryParams(page?: RequestPageParams['page']): void {
    if (!isUndefined(page) && this.activePage !== page) {
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: { page: page > 0 ? page : undefined },
        queryParamsHandling: 'merge',
      });
    }
  }

  getInsightStatements({ page }: FetchData) {
    this.loading = true;

    this.updateQueryParams(page);

    this.reportService
      .getInsightStatements({
        page: page ?? 0,
        size: 10,
        activeFilters: this.activeFilters,
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (statementList: InsightDocumentList) => {
          this.onFetchComplete(statementList);
        },
        error: (error) => {
          this.onFetchComplete();
          ErrorUtils.catchError('reportService.getInsightReports error', error);
        },
      });
  }

  onFetchComplete(statementList?: InsightDocumentList): void {
    const { items = [], totalElements = 0, totalPages = 0 } = statementList ?? {};
    this.statements = groupReportByDate(items);
    this.totalElements = totalElements;
    this.totalPages = totalPages;
    this.loading = false;
  }

  onModelChange() {
    this.activeFilters = { ...this.formModel };
    this.getInsightStatements({ page: 0 });
  }
}

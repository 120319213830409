import { AfterViewChecked, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { formRadioGroup, formSelect } from '@rocketfinancialcorp/rocket-ui/form';
import { FinancialAccountList, FinancialAccountListItem, FormModel, LinxCustomer, LinxRequestFinancialAccount } from '@shared/models';
import { FinancialAccountService, NotificationService } from '@shared/services';
import { Subject, takeUntil } from 'rxjs';
import { ErrorUtils } from '@shared/utils';

@Component({
  selector: 'app-linx-request-financial-account',
  templateUrl: './linx-request-financial-account.component.html',
})
export class LinxRequestFinancialAccountComponent implements OnInit, AfterViewChecked, OnDestroy {
  @Input() linxCustomer?: LinxCustomer;

  @Output() continue = new EventEmitter<LinxRequestFinancialAccount>();

  financialAccountForm = new UntypedFormGroup({});

  formModel: FormModel<{
    financialAccountType?: 'NEW' | 'EXISTING';
    newFinancialAccount?: string;
    existingFinancialAccount?: string;
  }> = {};

  financialAccountFields: FormlyFieldConfig[] = [
    formRadioGroup({
      key: 'financialAccountType',
      label: '',
      props: {
        options: [
          { label: 'Use Existing Financial Account', value: 'EXISTING' },
          { label: 'Create New Financial Account', value: 'NEW' },
        ],
        required: true,
      },
      expressions: {
        hide: () => this.isNewCustomer,
      },
    }),
    formRadioGroup({
      key: 'newFinancialAccount',
      label: 'Account Type',
      props: {
        options: [
          { label: 'Checking Account', value: 'CHECKING' },
          { label: 'Saving Account', value: 'SAVING' },
          { label: 'Card Account', value: 'CARD' },
        ],
        required: true,
      },
      expressions: {
        hide: (field: FormlyFieldConfig) => field.model.financialAccountType !== 'NEW' && !this.isNewCustomer,
      },
    }),
    formSelect({
      key: 'existingFinancialAccount',
      label: 'Financial Account',
      props: {
        options: [],
        placeholder: 'Select a Financial Account',
        required: true,
      },
      className: 'linx-financial-account-dropdown',
      expressions: {
        hide: (field: FormlyFieldConfig) => this.isNewCustomer || field.model.financialAccountType !== 'EXISTING',
      },
    }),
  ];

  financialAccountsLoading = false;

  financialAccounts!: FinancialAccountListItem[];

  get isNewCustomer(): boolean {
    return this.linxCustomer?.profileType === 'NEW';
  }

  private destroy$ = new Subject<void>();

  constructor(
    private financialAccountService: FinancialAccountService,
    private notificationService: NotificationService,
    private ref: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    if (!this.isNewCustomer) {
      this.getCustomerFinancialAccounts();
    }
  }

  ngAfterViewChecked(): void {
    this.ref.detectChanges();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onClickContinue(): void {
    const { financialAccountType = 'NEW', newFinancialAccount, existingFinancialAccount } = this.formModel;

    const formData = { financialAccountType } as LinxRequestFinancialAccount;

    if (financialAccountType === 'NEW') {
      formData.newFinancialAccount = newFinancialAccount;
    } else {
      formData.existingFinancialAccount = this.financialAccounts.find((item) => item.id === existingFinancialAccount);
    }

    this.continue.emit(formData);
  }

  onClearBtnClick(): void {
    this.financialAccountForm.reset();
  }

  getCustomerFinancialAccounts(): void {
    if (!this.linxCustomer?.id) {
      return;
    }
    this.financialAccountsLoading = true;

    this.financialAccountService
      .getCustomerFinancialAccounts({ customerId: this.linxCustomer.id })
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response) => {
          this.onFetchComplete(response);
        },
        error: (error) => {
          this.onFetchComplete();
          ErrorUtils.catchError('customerService.getCustomerFinancialAccounts', error);
        },
      });
  }

  onFetchComplete(response?: FinancialAccountList): void {
    const { items = [] } = response || {};

    this.financialAccounts = items;

    if (items.length) {
      const financialAccountOptions: { [key: string]: string | boolean }[] = [];

      items.forEach((item) => {
        const availableBalance = item.availableBalance ? item.availableBalance : '0.00';
        const account = {
          value: item.id,
          label: `${item.name ?? item.displayName} | Available Balance $ ${availableBalance}`,
        };
        financialAccountOptions.push(account);
      });
      if (this.financialAccountFields[2]?.props) {
        this.financialAccountFields[2].props.options = financialAccountOptions;
      }
    }

    this.financialAccountsLoading = false;
  }
}

import { Component, ElementRef, inject, OnInit } from '@angular/core';
import { ReportMenuOption, ReportNotification } from '@shared/models';
import { APP_ENV_CONFIG, ReportService } from '@shared/services';
import { Router } from '@angular/router';
import { NaturalLanguageSearchModalComponent } from '@shared/components';
import { ModalService } from '@rocketfinancialcorp/rocket-ui/modal';
import { embedDashboard } from '@superset-ui/embedded-sdk';
import { ErrorUtils } from '@shared/utils';

@Component({
  selector: 'app-reports-page',
  templateUrl: './reports-page.component.html',
  styleUrls: ['./reports-page.component.scss'],
})
export class ReportsPageComponent implements OnInit {
  envConfig = inject(APP_ENV_CONFIG);

  menuOptions: ReportMenuOption[] = [
    {
      icon: 'brainstorm',
      title: 'Ask me anything!',
      subtitle: 'What do you want to know today?',
      customClick: (e) => {
        e?.preventDefault();
        this.openSearchModal();
      },
    },
    {
      icon: 'fantasy',
      title: 'Superset Dashboard',
      subtitle: 'View your dashboard',
      customClick: (e) => {
        e?.preventDefault();
        this.viewSupersetDashboard();
      },
    },
    {
      icon: 'graph-report',
      title: 'Schedule a Custom Report',
      subtitle: 'Stay up-to-date with the performance',
      customClick: (e) => {
        e?.preventDefault();
        this.viewScheduledReports();
      },
    },
  ];

  reportNotifications: ReportNotification[] = [];

  constructor(
    private router: Router,
    private modalService: ModalService,
    private reportService: ReportService,
    private elementRef: ElementRef,
  ) {}

  ngOnInit() {
    this.initializeSupersetWidget();
    this.getReportNotificationStatus();
  }

  viewReports() {
    this.router.navigate(['app/insights/documents']);
  }

  viewSupersetDashboard() {
    window.open(`${this.envConfig.supersetDomain}`, '_blank');
  }

  viewScheduledReports() {
    this.router.navigate(['app/insights/documents/scheduled-report/list']);
  }

  openSearchModal() {
    this.modalService.open(NaturalLanguageSearchModalComponent, {
      className: 'natural-language-search-modal',
    });
  }

  initializeSupersetWidget() {
    this.reportService.supersetInitConfig().subscribe({
      next: (response) => {
        const dashboardElement = this.elementRef.nativeElement.querySelector('#dashboard');
        if (dashboardElement) {
          embedDashboard({
            id: response.embeddingUuid,
            supersetDomain: this.envConfig.supersetDomain,
            mountPoint: dashboardElement,
            fetchGuestToken: () => Promise.resolve(response.guestToken),
            dashboardUiConfig: {
              hideTitle: true,
              filters: {
                expanded: false,
              },
            },
          }).then(
            () => {
              const iframe = dashboardElement.querySelector('iframe');
              if (iframe) {
                iframe.style.width = '100%';
                iframe.style.height = 'calc(100vh - 14.625rem)';
              }
            },
            (err) => {
              ErrorUtils.catchError('reportService supersetInitConfig', err);
            },
          );
        }
      },
    });
  }

  getReportNotificationStatus() {
    this.reportService.getInsightReportStatus().subscribe({
      next: (response) => {
        this.reportNotifications = response;
      },
      error: (err) => {
        ErrorUtils.catchError('reportService getInsightReportStatus', err);
      },
    });
  }
}

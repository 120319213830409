import { createFeature, createReducer, createSelector, on } from '@ngrx/store';
import { ExceptionListItem, FilterValues } from '@shared/models';
import { ExceptionManagementActions } from './exception-management.actions';

export interface ExceptionManagementState {
  exceptions: ExceptionListItem[];
  exceptionsTotalElements: number;
  exceptionsActiveFilters: FilterValues | null;
  isLoading: boolean;
}

export const exceptionManagementInitialState: ExceptionManagementState = {
  exceptions: [],
  exceptionsTotalElements: 0,
  exceptionsActiveFilters: { date: 'DEFAULT', status: 'NEW,IN_PROGRESS,REOPENED' },
  isLoading: false,
};

export const exceptionManagementFeature = createFeature({
  name: 'exceptionManagementFeature',
  reducer: createReducer(
    exceptionManagementInitialState,
    on(
      ExceptionManagementActions.loadExceptions,
      (state): ExceptionManagementState => ({ ...state, exceptions: [], exceptionsTotalElements: 0, isLoading: true }),
    ),
    on(ExceptionManagementActions.loadExceptionsSuccess, (state, { exceptionListItems, totalElements }): ExceptionManagementState => {
      return { ...state, exceptions: exceptionListItems, exceptionsTotalElements: totalElements, isLoading: false };
    }),
    on(ExceptionManagementActions.loadExceptionsFailure, (state): ExceptionManagementState => {
      return { ...state, exceptions: [], exceptionsTotalElements: 0, isLoading: false };
    }),
    on(ExceptionManagementActions.setExceptionsActiveFilters, (state, { filters }): ExceptionManagementState => {
      return { ...state, exceptionsActiveFilters: filters };
    }),
  ),
});

export const selectExceptionsActiveFiltersRequest = createSelector(
  exceptionManagementFeature.selectExceptionsActiveFilters,
  (activeFilters) => {
    if (!activeFilters) {
      return;
    }

    const filters: Record<string, string> = {};
    Object.keys(activeFilters).map((filterKey) => {
      const value = activeFilters[filterKey];
      if (value && value !== 'DEFAULT' && value !== 'all') {
        filters[filterKey] = value;
      }
    });

    return filters;
  },
);

export const selectExceptionsActiveFiltersCount = createSelector(
  exceptionManagementFeature.selectExceptionsActiveFilters,
  (activeFilters) => {
    if (!activeFilters) {
      return 0;
    }

    return Object.values(activeFilters).filter(
      (value) => !!value && value !== 'DEFAULT' && value !== 'all' && value !== 'NEW,IN_PROGRESS,REOPENED',
    ).length;
  },
);

import { AbstractControl, ValidationErrors } from '@angular/forms';
import { formCheckboxGroup, formRadioGroup, formRow, RktFormFieldConfig } from '@rocketfinancialcorp/rocket-ui/form';
import { LinxCustomerType } from '@shared/constants';
import { compact } from 'lodash-es';

export const linxRequestProfileFields = (customerType?: LinxCustomerType): RktFormFieldConfig[] => {
  return [
    {
      fieldGroup: [
        formRow([
          formCheckboxGroup({
            key: 'personalInfo',
            label: 'Personal Information',
            props: {
              options: [
                { value: 'salutation', label: 'Salutation' },
                { value: 'sex', label: 'Sex' },
                { value: 'socialId', label: 'SSN' },
                { value: 'socialIdCountryCode', label: 'SSN Country' },
                { value: 'dateOfBirth', label: 'Date of Birth' },
                { value: 'cityOfBirth', label: 'City of Birth' },
                { value: 'countryOfBirthCode', label: 'Country of Birth' },
                { value: 'citizenshipCountryCode', label: 'Citizenship Country' },
              ],
            },
            expressions: {
              hide: () => customerType !== 'INDIVIDUAL',
            },
          }),
          formCheckboxGroup({
            key: 'businessInfo',
            label: 'Business Information',
            props: {
              options: [
                { value: 'doingBusinessAsName', label: 'Business DBA Name' },
                { value: 'legalName', label: 'Business Legal Name' },
                { value: 'legalEntityType', label: 'Legal Entity type' },
                { value: 'dateOfFormation', label: 'Date of Formation' },
                { value: 'taxId', label: 'Business Tax ID' },
                { value: 'taxIdCountryCode', label: 'Business Tax ID Country (US)' },
                { value: 'industryCode', label: 'Industry' },
              ],
            },
            expressions: {
              hide: () => customerType !== 'BUSINESS',
            },
          }),
          formRadioGroup({
            key: 'phoneNumber',
            label: customerType === 'BUSINESS' ? 'Primary Phone of Business' : 'Phone Number Options',
            props: {
              options: [
                { value: 'WORK', label: 'Work' },
                { value: 'HOME', label: 'Home' },
              ],
            },
          }),
          formCheckboxGroup({
            key: 'addresses',
            label: 'Address Options',
            props: {
              options: [
                { value: 'PHYSICAL', label: 'Physical' },
                { value: 'MAILING', label: 'Mailing' },
                { value: 'SHIPPING', label: 'Shipping' },
              ],
            },
          }),
        ]),
      ],
      validators: {
        required: (control: AbstractControl): ValidationErrors | null => {
          const controlArray = compact(Object.keys(control.value || {}).map((key) => control.value[key]));
          const nonEmpty: string[] = [];
          controlArray.forEach((item) => {
            const selectedOptions = Object.keys(item).filter((el) => item[el]);
            nonEmpty.push(...selectedOptions);
          });
          return nonEmpty.length ? { required: { message: 'error' } } : null;
        },
      },
    },
  ];
};

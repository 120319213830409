import { Component, Input } from '@angular/core';
import { InsightDocumentListItem, InsightDocument } from '@shared/models';
import { ErrorUtils } from '@shared/utils';
import { ViewPdfDocumentComponent } from '@shared/components';
import { NotificationService, ReportService } from '@shared/services';
import { ModalService } from '@rocketfinancialcorp/rocket-ui/modal';
import { compact } from 'lodash/index';
import { MenuItem } from '@rocketfinancialcorp/rocket-ui/menu';

@Component({
  selector: 'app-report-list-item',
  templateUrl: './report-list-item.component.html',
  styleUrls: ['./report-list-item.component.scss'],
})
export class ReportListItemComponent {
  @Input() documentListItem!: InsightDocumentListItem;

  constructor(private reportService: ReportService, private modalService: ModalService, private notificationService: NotificationService) {}

  downloadReport(documentId: string | undefined, documentType: string) {
    if (!documentId) return;
    this.exportDocument(documentId, documentType, true);
  }

  exportDocument(documentId: string, documentType: string, isDownload?: boolean): void {
    this.reportService.getInsightReportPreSignedURL(documentId, documentType).subscribe({
      next: (response) => {
        const { signedJsonUrl } = response;

        if (isDownload) {
          window.open(signedJsonUrl, '_self');
        } else {
          this.previewDocument(signedJsonUrl);
        }
      },
      error: (error) => {
        this.notificationService.displayError('Unable to download document.');
        ErrorUtils.catchError('reportService.getPreSignedURL error', error);
      },
    });
  }

  previewDocument(documentURL: string) {
    const previewDocumentRef = this.modalService.open(ViewPdfDocumentComponent, {
      className: 'preview-document-modal',
      size: 'lg',
    });

    previewDocumentRef.componentInstance.modalInitData({
      documentURL,
      showPrintButton: true,
      showDownloadButton: true,
      showZoomButton: true,
      containerHeight: '40rem',
    });
  }

  getDropdownMenuItems(document: InsightDocument): MenuItem[] {
    return compact([
      document.pdfStatementPath
        ? {
            label: 'Download PDF',
            command: (c) => {
              this.downloadReport(c?.elementId, 'PDF');
            },
            type: 'link',
          }
        : undefined,
      {
        label: 'Download CSV',
        command: (c) => {
          this.downloadReport(c?.elementId, 'CSV');
        },
        type: 'link',
      },
    ]);
  }
}

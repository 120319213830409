import { Injectable } from '@angular/core';
import { LinxCustomerProfileType, LinxCustomerType } from '@shared/constants';
import {
  LinxRequestDocumentDetails,
  LinxRequestMoveMoneyDetails,
  LinxRequestOptionType,
  LinxRequestRowType,
  LinxTemplateFinancialAccount,
} from '@shared/models';

export abstract class AbstractLinxTemplateBase {
  abstract expandedRow?: LinxRequestRowType;
  abstract templateName: string;
  abstract customerType?: LinxCustomerType;
  abstract customerProfileType?: LinxCustomerProfileType;
  abstract selectedOptions: LinxRequestOptionType[];
  abstract enhanceProfileDetails?: { [key: string]: string[] };
  abstract financialAccountDetails?: LinxTemplateFinancialAccount;
  abstract documentAcceptanceDetails?: LinxRequestDocumentDetails[];
  abstract moveMoneyDetails?: LinxRequestMoveMoneyDetails;
  abstract resetRequestRowDetails(type: LinxRequestRowType): void;
  abstract handleRowExpand(type?: LinxRequestRowType): void;
  abstract isRequestOptionsAvailable(): boolean;
  abstract isRowVisible(type: LinxRequestRowType): boolean;
  abstract isRowExpanded(type: LinxRequestRowType): boolean;
  abstract isRowHasValue(type: LinxRequestRowType): boolean;
  abstract isEditDisabled(): boolean;
  abstract onReset(): void;
}

@Injectable({
  providedIn: 'root',
})
export class LinxTemplateWrapperService {
  public expandedRow?: LinxRequestRowType;

  templateName: string = '';

  customerType?: LinxCustomerType;

  customerProfileType?: LinxCustomerProfileType;

  enhanceProfileDetails?: { [key: string]: string[] };

  financialAccountDetails?: LinxTemplateFinancialAccount;

  documentAcceptanceDetails?: LinxRequestDocumentDetails[];

  moveMoneyDetails?: LinxRequestMoveMoneyDetails;

  selectedOptions: LinxRequestOptionType[] = [];

  isRequestOptionsAvailable(): boolean {
    return this.selectedOptions.length > 0;
  }

  isRowVisible(type: LinxRequestRowType): boolean {
    return this.selectedOptions.find((option) => option.type === type)?.isChecked ?? false;
  }

  isRowExpanded(type: LinxRequestRowType): boolean {
    return this.expandedRow === type;
  }

  isRowHasValue(type: string): boolean {
    switch (type) {
      case 'enhance-profile':
        return !!this.enhanceProfileDetails;
      case 'financial-account':
        return !!this.financialAccountDetails;
      case 'document-acceptance':
        return this.documentAcceptanceDetails?.length ? true : false;
      case 'move-money':
        return !!this.moveMoneyDetails;
      default:
        return false;
    }
  }

  isEditDisabled(): boolean {
    return this.expandedRow ? true : false;
  }

  resetRequestRowDetails(type: LinxRequestRowType): void {
    switch (type) {
      case 'enhance-profile':
        this.enhanceProfileDetails = undefined;
        break;
      case 'financial-account':
        this.financialAccountDetails = undefined;
        break;
      case 'document-acceptance':
        this.documentAcceptanceDetails = undefined;
        break;
      case 'move-money':
        this.moveMoneyDetails = undefined;
        break;
      default:
        break;
    }
  }

  onReset(): void {
    this.financialAccountDetails = undefined;
    this.enhanceProfileDetails = undefined;
    this.documentAcceptanceDetails = undefined;
    this.moveMoneyDetails = undefined;
    this.expandedRow = undefined;
    this.selectedOptions = [];
  }

  handleRowExpand(type?: LinxRequestRowType): void {
    if (type) {
      this.expandedRow = type;
      return;
    }
    this.expandedRow = this.selectedOptions.find((option) => option.isChecked && !option.hasValue)?.type;
  }
}

<div *ngIf="documentListItem">
  <div class="date-category-name">{{ documentListItem.groupCategory }}</div>

  <div *ngFor="let document of documentListItem.documents" class="document-list-item" [title]="document.name">
    <div class="document-details">
      <rkt-icon name="report" class="rocket-icon"></rkt-icon>
      <div class="document-title">
        <div class="report-name">{{ document?.name | titlecase }}</div>
        <div class="document-timestamp">{{ document.createdAt | date: 'medium' }}</div>
      </div>
    </div>

    <div class="document-actions">
      <button class="file-icon" (click)="exportDocument(document.id, 'PDF')" title="Preview Report" *ngIf="document?.pdfStatementPath">
        <rkt-icon name="view-file" class="rocket-icon"></rkt-icon>
      </button>

      <button (click)="dropdownMenu.toggle($event, document.id)" title="Download Report">
        <rkt-icon name="download" class="rocket-icon"></rkt-icon>
      </button>

      <rkt-menu className="report-dropdown" #dropdownMenu [model]="getDropdownMenuItems(document)"></rkt-menu>
    </div>
  </div>
</div>

import { inject, Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { isEmpty } from 'lodash-es';
import { format, sub } from 'date-fns';
import { environment } from '@env';
import { BackendService } from '@shared/services/backend.service';
import { ExceptionListItem, ExceptionListResponse, RequestPageParams } from '@shared/models';
import { CustomHttpParamEncoder } from '@shared/encoder';
import { constants } from '@shared/constants';
import { exceptionCategory, exceptionType, filterCreatedAtDateRange, systemTimeZone } from '@shared/utils';

@Injectable({
  providedIn: 'root',
})
export class ExceptionManagementService {
  backendService = inject(BackendService);

  public getExeptions({
    businessAccountId,
    requestParams,
  }: {
    businessAccountId: string;
    requestParams: RequestPageParams;
  }): Observable<ExceptionListItem[]> {
    const { page, size, activeFilters, sortParams } = requestParams;

    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set('page', `${page ?? 0}`)
      .set('size', `${size ?? constants.TABLE_ROWS}`)
      .set('businessAccountId', businessAccountId);

    if (sortParams) {
      params = params.append('sort', `${sortParams.key},${sortParams.sortDir}`);
    }

    if (!isEmpty(activeFilters)) {
      Object.keys(activeFilters).forEach((key) => {
        if (key === 'date') {
          const defaultRange = {
            from: format(sub(new Date(), { days: 30 }), 'yyyy-MM-dd'),
            to: format(new Date(), 'yyyy-MM-dd'),
          };

          const { from, to } = filterCreatedAtDateRange(activeFilters[key], defaultRange) ?? {};

          if (!from || !to) {
            return;
          }

          if (key === 'date') {
            params = params.append('dateFrom', from);
            params = params.append('dateTo', to);
            params = params.append('timeZone', systemTimeZone);
            return;
          }
        } else {
          params = params.append(key, activeFilters[key]!);
        }
      });
    }

    return this.backendService
      .get<ExceptionListResponse>(`${environment.exceptionManagementEndpoint}/exceptions`, {
        params,
      })
      .pipe(
        map((response) => {
          const { content = [] } = response;

          return content.map((item) => {
            return {
              ...item,
              typeLabel: exceptionType[item.type] ?? item.type,
              categoryLabel: exceptionCategory[item.category] ?? item.category,
              assignee: ' ::',
            };
          });
        }),
      );
  }
}

import { Component } from '@angular/core';
import { FormModel, ScheduledReport } from '@shared/models';
import { formDatepicker, formInput, formRow, formSelect } from '@rocketfinancialcorp/rocket-ui/form';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormGroup } from '@angular/forms';
import { ActiveModal, ModalService } from '@rocketfinancialcorp/rocket-ui/modal';
import { ErrorUtils, formatDate, systemTimeZone } from '@shared/utils';
import { utcToZonedTime } from 'date-fns-tz';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { ReportService } from '@shared/services';
import { finalize, Subject, takeUntil } from 'rxjs';
import { MESSAGE } from '@shared/constants';
import { SuccessModalComponent } from '@shared/components';

@Component({
  selector: 'app-scheduled-report-update',
  templateUrl: './scheduled-report-update.component.html',
})
export class ScheduledReportUpdateComponent {
  scheduledReport!: ScheduledReport;

  public reportModel: FormModel<ScheduledReport> = {};

  public updateReportForm = new FormGroup({});

  updateFormFields: FormlyFieldConfig[] = [
    formRow([
      formInput({
        key: 'name',
        label: 'Report Name',
        props: { required: false, minLength: 1, maxLength: 100 },
      }),
    ]),

    formRow([
      formDatepicker({
        key: 'endAt',
        label: 'End Date',
        props: {
          placeholder: 'MM/DD/YY',
          minDate: formatDate(utcToZonedTime(new Date(), systemTimeZone)),
          maxDate: NgbDate.from({ day: 1, month: 1, year: new Date().getFullYear() + 120 }),
        },
      }),
    ]),

    formRow([
      formSelect({
        key: 'schedulerType',
        label: 'Schedule Frequency',
        props: {
          options: [
            { value: 'DAILY', label: 'Daily' },
            { value: 'WEEKLY', label: 'Weekly' },
            { value: 'MONTHLY', label: 'Monthly' },
          ],
          required: false,
        },
      }),
      formSelect({
        key: 'includeCsvValueMapped',
        label: 'Include CSV Report',
        props: {
          options: [
            { value: 'true', label: 'Sure thing!' },
            { value: 'false', label: 'No, thanks.' },
          ],
          required: false,
        },
      }),
    ]),
  ];

  submitError = '';

  isLoading = false;

  private destroy$ = new Subject<void>();

  constructor(public activeModal: ActiveModal, private reportService: ReportService, private modalService: ModalService) {}

  modalInitData(scheduledReport: ScheduledReport): void {
    const { name, schedulerType, graphicalReportTypeId, id, includeCsvValue, endAt } = scheduledReport;

    this.scheduledReport = scheduledReport;

    this.reportModel = {
      ...this.reportModel,
      name,
      schedulerType,
      graphicalReportTypeId,
      id,
      includeCsvValueMapped: includeCsvValue?.toString(),
      endAt,
    };
  }

  onSave() {
    this.updateReportForm.markAllAsTouched();

    if (this.updateReportForm.invalid || !this.scheduledReport) return;

    this.isLoading = true;

    this.submitError = '';

    const { name, schedulerType, graphicalReportTypeId, id, includeCsvValueMapped, endAt } = this.reportModel;

    const reportUpdateData: Partial<ScheduledReport> = {
      name,
      schedulerType,
      graphicalReportTypeId,
      id,
      includeCsvValue: includeCsvValueMapped === 'true',
      endAt: endAt ? new Date(endAt).toISOString() : undefined,
    };

    this.reportService
      .updateScheduledReport(this.scheduledReport.id, reportUpdateData)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: () => {
          this.handleSuccess();
        },
        error: (error) => {
          this.handleError(error);
        },
      });
  }

  private handleSuccess() {
    const successModalRef = this.modalService.open(SuccessModalComponent, { className: 'confirm-modal' });
    successModalRef.componentInstance.actionName = 'updated';
    successModalRef.componentInstance.type = 'Scheduled Report';
    successModalRef.result.finally(() => this.activeModal.close(true));
  }

  private handleError(error?: string | number): void {
    if (typeof error === 'string') {
      this.submitError = `Error: ${error}`;
    } else {
      this.submitError = error === 403 ? MESSAGE.PERMISSION_DENIED : MESSAGE.GENERIC_ERROR;
    }
    ErrorUtils.catchError('customerService.updateCustomer', error);
  }
}

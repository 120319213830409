<div class="document-list-container">
  <div class="document-container">
    <div class="document-page-container" [hidden]="isExcludedPage">
      <div class="document-header">
        <div class="document-title">Documents</div>

        <div class="action-buttons">
          <button (click)="onSearchReports()">
            <rkt-icon name="search"></rkt-icon>
          </button>

          <button (click)="closeSideMenu()">
            <rkt-icon name="times"></rkt-icon>
          </button>
        </div>
      </div>

      <app-tabs [tabItems]="tabItems" [noHorizontalOffset]="true"></app-tabs>
    </div>
    <router-outlet></router-outlet>
  </div>
</div>

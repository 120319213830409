import { Component, OnInit } from '@angular/core';
import { FilterValues, FormModel, RequestPageParams, InsightDocumentListItem, InsightDocumentList } from '@shared/models';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService, ReportService } from '@shared/services';
import { isUndefined } from 'lodash/index';
import { FetchData } from '@rocketfinancialcorp/rocket-ui/table';
import { takeUntil } from 'rxjs/operators';
import { ErrorUtils, activeFilters, groupReportByDate } from '@shared/utils';
import { FormGroup } from '@angular/forms';
import { ReportFormFields } from '@modules/insights/components';

@Component({
  selector: 'app-insight-report-page',
  templateUrl: './insight-report-page.component.html',
  styleUrls: ['./insight-report-page.component.scss'],
})
export class InsightReportPageComponent implements OnInit {
  filteredReports!: InsightDocumentListItem[];

  loading!: boolean;

  totalElements = 0;

  searchString?: string;

  totalPages = 0;

  activeFilters: FilterValues = {};

  formFields = ReportFormFields;

  document?: ArrayBuffer;

  reportFilterForm = new FormGroup({});

  formModel: FormModel<{
    reportType: string;
    dateRange: string;
  }> = {};

  get activePage(): number {
    const routePage = this.activatedRoute?.snapshot?.queryParams?.page;
    return routePage ? parseInt(routePage, 10) : 0;
  }

  private destroy$ = new Subject<void>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private reportService: ReportService,
    private router: Router,
    private notificationService: NotificationService,
  ) {}

  ngOnInit(): void {
    this.initializeReportTypeList();
    this.activeFilters = activeFilters({ reportType: 'ALL', dateRange: 'THIS_WEEK' });
    this.getInsightReports({ page: 0 });
  }

  updateQueryParams(page?: RequestPageParams['page']): void {
    if (!isUndefined(page) && this.activePage !== page) {
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: { page: page > 0 ? page : undefined },
        queryParamsHandling: 'merge',
      });
    }
  }

  getInsightReports({ page }: FetchData) {
    this.loading = true;

    this.updateQueryParams(page);

    this.reportService
      .getInsightReports({
        page: page ?? 0,
        size: 10,
        searchString: this.searchString,
        activeFilters: this.activeFilters,
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (reportList) => {
          this.onFetchComplete(reportList);
        },
        error: (error) => {
          this.onFetchComplete();
          ErrorUtils.catchError('reportService.getInsightReports error', error);
        },
      });
  }

  onFetchComplete(reportList?: InsightDocumentList) {
    const { items = [], totalElements = 0, totalPages = 0 } = reportList ?? {};
    this.filteredReports = groupReportByDate(items);
    this.totalElements = totalElements;
    this.totalPages = totalPages;
    this.loading = false;
  }

  onSearch(event: string): void {
    if (!event) {
      this.onSearchReset();
      return;
    }
    this.searchString = event;
    this.getInsightReports({ page: 0 });
  }

  onSearchReset(): void {
    if (!this.searchString) {
      return;
    }

    this.searchString = undefined;
    this.getInsightReports({ page: 0 });
  }

  onModelChange() {
    this.activeFilters = { ...this.formModel };
    this.getInsightReports({ page: 0 });
  }

  navigateToScheduledReport() {
    this.router.navigateByUrl('app/insights/documents/scheduled-report');
  }

  private initializeReportTypeList() {
    this.reportService
      .getInsightReportTypes()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (reportTypes) => {
          const reportOptions = [
            {
              label: 'All',
              value: 'ALL',
            },
            ...reportTypes,
          ];

          if (this.formFields[0]?.fieldGroup[0]?.props) {
            this.formFields[0].fieldGroup[0].props.options = reportOptions;
          }
        },
        error: (err) => {
          this.notificationService.displayError('Unable to fetch report types.');
          ErrorUtils.catchError('reportService.getReportTypes error', err);
        },
      });
  }
}

import { createActionGroup, props } from '@ngrx/store';
import { ErrorMessage, ExceptionListItem, FilterValues, RequestPageParams } from '@shared/models';

export const ExceptionManagementActions = createActionGroup({
  source: 'Exception Management',
  events: {
    'Load Exceptions': props<{ params: RequestPageParams }>(),
    'Load Exceptions Success': props<{ exceptionListItems: ExceptionListItem[]; totalElements: number }>(),
    'Load Exceptions Failure': props<{ error: ErrorMessage }>(),
    'Set Exceptions Active Filters': props<{ filters: FilterValues }>(),
  },
});

<div class="statement-list">
  <div class="statement-form-container">
    <rkt-form [form]="statementFilterForm" [fields]="statementFormFields" [model]="formModel" (modelChange)="onModelChange()"></rkt-form>
  </div>

  <ng-container *ngIf="statements?.length; else onFetchDocuments">
    <div class="statement-container-label">Monthly statements are posted around the last day of each month.</div>
    <div *ngFor="let documentList of statements">
      <app-report-list-item [documentListItem]="documentList"></app-report-list-item>
    </div>

    <app-pagination [totalPages]="totalPages" [activePage]="activePage" (updatePageParam)="getInsightStatements($event)"></app-pagination>
  </ng-container>

  <ng-template #onFetchDocuments>
    <div class="empty-state" *ngIf="!loading">
      <div class="image"></div>
      <div class="message">No Statement Available</div>
    </div>

    <ng-container *ngIf="loading">
      <div *ngFor="let i of [].constructor(7)" class="document-list-item"></div>
    </ng-container>
  </ng-template>
</div>

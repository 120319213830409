import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ClipboardModule } from '@angular/cdk/clipboard';

// Other UI libraries
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastNoAnimationModule } from 'ngx-toastr';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgxMaskModule } from 'ngx-mask';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';

// ROCKET UI
import { RktMenuModule } from '@rocketfinancialcorp/rocket-ui/menu';
import { RktModalModule } from '@rocketfinancialcorp/rocket-ui/modal';
import { RktTableModule } from '@rocketfinancialcorp/rocket-ui/table';
import { RktButtonModule } from '@rocketfinancialcorp/rocket-ui/button';
import { RktFormModule } from '@rocketfinancialcorp/rocket-ui/form';
import { RktCarouselModule } from '@rocketfinancialcorp/rocket-ui/carousel';
import {
  RktIconModule,
  RktIconRegistryService,
  info,
  edit,
  arrowLeft,
  filter,
  trash,
  suspend,
  resume,
  plus,
  bank,
  card,
  check,
  times,
  chevronUp,
  chevronDown,
  dashboard,
  moveMoney,
  myAccount,
  team,
  campaign,
  location,
  mail,
  mailing,
  phone,
  profile,
  save,
  moneyBag,
  request,
  resend,
  calendar,
  send,
  moneyRequest,
  ach,
  transfer,
  note,
  close,
  reverse,
  monitor,
  document,
  sameDay,
  user,
  arrowRight,
  lock,
  upload,
  identificationChecked,
  passport,
  driverLicense,
  company,
  accounting,
  businessman,
  done,
  depositAccounts,
  dispute,
  briefcase,
  beneficiary,
  insight,
  cashInHand,
  shipping,
  star,
  minusSign,
  error,
  notification,
  download,
  copy,
  externalLink,
  bulb,
  attachment,
  eye,
  eyeSlash,
  link,
  tiles,
  list,
  brainstorm,
  graphReport,
  fantasy,
  chat,
  schedule,
  newDocument,
  report,
  wallClock,
  viewFile,
} from '@rocketfinancialcorp/rocket-ui/icon';

interface RktIcon<T> {
  name: T;
  data: string;
}

const clock: RktIcon<'clock'> = {
  name: 'clock',
  data: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 21 21"><path fill="#fff" d="M10.5 3C6.35775 3 3 6.35775 3 10.5c0 4.1423 3.35775 7.5 7.5 7.5 4.1423 0 7.5-3.3577 7.5-7.5C18 6.35775 14.6423 3 10.5 3Zm2.4698 11.0303L9.75 10.8105V6h1.5v4.1895l2.7803 2.7803-1.0605 1.0605Z"/></svg>`,
};

const exceptionsList: RktIcon<'exceptions-list'> = {
  name: 'exceptions-list',
  data: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><path fill="currentColor" d="m5.586 3-2 2H2v2h2.414L7 4.414 5.586 3ZM9 5v2h13V5H9ZM5.586 9l-2 2H2v2h2.414L7 10.414 5.586 9ZM9 11v2h13v-2H9Zm-5 5.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3Zm5 .5v2h13v-2H9Z"/></svg>`,
};

@NgModule({
  imports: [
    NgbModule,
    NgSelectModule,
    NgxDatatableModule,
    ToastNoAnimationModule.forRoot({
      preventDuplicates: true,
    }),
    NgxMaskModule.forRoot({ allowNegativeNumbers: true }),
    NgScrollbarModule,
    NgxExtendedPdfViewerModule,
    ClipboardModule,
  ],
  declarations: [],
  exports: [
    CommonModule,
    FormsModule,
    ClipboardModule,
    ReactiveFormsModule,
    NgSelectModule,
    HttpClientModule,
    NgbModule,
    NgxDatatableModule,
    NgxMaskModule,
    NgScrollbarModule,
    NgxExtendedPdfViewerModule,
    // ROCKET UI
    RktIconModule,
    RktTableModule,
    RktButtonModule,
    RktFormModule,
    RktMenuModule,
    RktModalModule,
    RktCarouselModule,
  ],
  providers: [],
})
export class PortalSharedLibsModule {
  constructor(private iconService: RktIconRegistryService) {
    this.iconService.registerIcons([
      info,
      edit,
      arrowLeft,
      filter,
      trash,
      suspend,
      resume,
      bank,
      card,
      plus,
      check,
      times,
      chevronUp,
      chevronDown,
      dashboard,
      moveMoney,
      myAccount,
      team,
      campaign,
      location,
      mail,
      mailing,
      phone,
      profile,
      save,
      moneyBag,
      request,
      resend,
      calendar,
      send,
      moneyRequest,
      ach,
      transfer,
      note,
      close,
      reverse,
      monitor,
      document,
      sameDay,
      user,
      arrowRight,
      lock,
      upload,
      identificationChecked,
      passport,
      driverLicense,
      company,
      accounting,
      businessman,
      done,
      depositAccounts,
      dispute,
      briefcase,
      beneficiary,
      insight,
      cashInHand,
      shipping,
      star,
      minusSign,
      error,
      notification,
      download,
      link,
      copy,
      externalLink,
      bulb,
      attachment,
      eye,
      eyeSlash,
      tiles,
      list,
      brainstorm,
      fantasy,
      graphReport,
      chat,
      clock,
      schedule,
      newDocument,
      report,
      wallClock,
      viewFile,
      exceptionsList,
    ]);
  }
}

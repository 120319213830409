import { inject, Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { catchError, map, tap, switchMap, mergeMap } from 'rxjs/operators';
import { ErrorUtils } from '@shared/utils';
import { MessagesActions, fromAuth, selectExceptionsActiveFiltersRequest } from '@shared/store';
import { ExceptionManagementService } from '@shared/services';
import { ExceptionManagementActions } from './exception-management.actions';

@Injectable()
export class ExceptionManagementEffects {
  actions$ = inject(Actions);

  store = inject(Store);

  router = inject(Router);

  exceptionManagementService = inject(ExceptionManagementService);

  loadExceptions$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ExceptionManagementActions.loadExceptions),
      concatLatestFrom(() => [
        this.store.select(fromAuth.selectBusinessAccountId),
        this.store.select(selectExceptionsActiveFiltersRequest),
      ]),
      mergeMap(([payload, activeBusinessAccountId, activeFilters]) => {
        return this.exceptionManagementService
          .getExeptions({
            businessAccountId: activeBusinessAccountId,
            requestParams: { ...payload.params, activeFilters },
          })
          .pipe(
            map((exceptionListItems) => {
              return ExceptionManagementActions.loadExceptionsSuccess({
                exceptionListItems,
                totalElements: exceptionListItems.length,
              });
            }),
            catchError((error) => {
              return of(ExceptionManagementActions.loadExceptionsFailure({ error }));
            }),
          );
      }),
    );
  });

  loadExceptionsFailure$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ExceptionManagementActions.loadExceptionsFailure),
      tap({
        next: (errorResponse) => {
          ErrorUtils.catchError('exceptionManagementService.getExceptions - Error', errorResponse.error);
        },
      }),
      switchMap(() => of(MessagesActions.displayError({ message: 'Unable to fetch Exceptions.' }))),
    );
  });
}

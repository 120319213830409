import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { constants } from '@shared/constants';
import { CustomHttpParamEncoder } from '@shared/encoder';
import {
  AdaRegistration,
  AdaRegistrationEditParams,
  AdaRegistrationList,
  AdaRegistrationListRaw,
  AdaRegistrationRaw,
  FilterValues,
  RequestPageParams,
} from '@shared/models';
import { BehaviorSubject, Observable, catchError, map, tap, throwError } from 'rxjs';
import { BackendService } from '../backend.service';
import { StorageService } from '../storage/storage.service';
import { environment } from '@env';
import { AuditActions } from '@shared/store';
import { compact } from 'lodash-es';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export class AdaRegistrationService {
  adaRegistrationListFilter = new BehaviorSubject<FilterValues>({});

  constructor(private backendService: BackendService, private storageService: StorageService, private store: Store) {}

  public getAdaRegistrations({ page, size, searchString, sortParams, activeFilters }: RequestPageParams): Observable<AdaRegistrationList> {
    const pageSize = size ?? constants.TABLE_ROWS;
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() });

    params = params.append('size', pageSize);

    if (page) {
      params = params.append('page', page);
    }

    if (searchString) {
      params = params.append('search', searchString);
    }

    if (sortParams) {
      params = params.append('sort', `${sortParams.key},${sortParams.sortDir}`);
    }

    if (activeFilters) {
      Object.keys(activeFilters).forEach((key) => {
        params = params.append(key, activeFilters[key]!);
      });
    }

    return this.backendService.get<AdaRegistrationListRaw>(`${environment.financialAccountFlow}/v1/debit-authorizations`, { params }).pipe(
      map((response) => {
        const { content = [], totalElements = 0 } = response || {};
        return {
          totalElements,
          items: content.map((registration) => this.mapAdaRegistration(registration)),
        };
      }),
      catchError((errorRes) => throwError(() => errorRes)),
    );
  }

  public getAdaRegistrationById(adaRegistrationId: string): Observable<AdaRegistration> {
    return this.backendService
      .get<AdaRegistrationRaw>(`${environment.financialAccountFlow}/v1/debit-authorizations/${adaRegistrationId}`)
      .pipe(
        tap({
          next: (response) => {
            const { createdBy, updatedBy } = response;
            this.store.dispatch(AuditActions.loadTeamMembers({ ids: compact([createdBy, updatedBy]) }));
          },
        }),
        map((response) => this.mapAdaRegistration(response)),
        catchError((errorRes) => throwError(() => errorRes)),
      );
  }

  private mapAdaRegistration(adaRegistration: AdaRegistrationRaw): AdaRegistration {
    return {
      ...adaRegistration,
      customerName: adaRegistration.accountHolderDetails?.fullName,
      shortId: adaRegistration.id.split('-')[0],
      accountHolderShortId: adaRegistration.accountHolderDetails?.id.split('-')[0],
      financialAccountShortId: adaRegistration.financialAccountId.split('-')[0],
      authenticationData: adaRegistration.authenticationData
        ? {
            ...adaRegistration.authenticationData,
            currentLocation: `${adaRegistration.authenticationData.geoLocation.latitude}, ${adaRegistration.authenticationData.geoLocation.longitude}`,
          }
        : undefined,
    };
  }

  public setAdaRegistrationFilterParams(filters: FilterValues): void {
    this.storageService.setItem(constants.KOR_ADA_REGISTRATION_FILTER, filters);
    this.updateAdaRegistrationFilterParams(filters);
  }

  public getAdaRegistrationFilterParams(): void {
    const filters = this.storageService.getItem<FilterValues>(constants.KOR_ADA_REGISTRATION_FILTER);

    if (filters && Object.keys(filters).length) {
      this.updateAdaRegistrationFilterParams(filters);
    }
  }

  private updateAdaRegistrationFilterParams(filters: FilterValues): void {
    this.adaRegistrationListFilter.next(filters);
  }

  updateAdaRegistrationStatus(action: string, adaRegistrationId: string): Observable<AdaRegistration> {
    return this.backendService
      .update<AdaRegistrationRaw>(`${environment.financialAccountFlow}/v1/debit-authorizations/${adaRegistrationId}/${action}`)
      .pipe(
        tap({
          next: (response) => {
            const { createdBy, updatedBy } = response;
            this.store.dispatch(AuditActions.loadTeamMembers({ ids: compact([createdBy, updatedBy]) }));
          },
        }),
        map((response) => this.mapAdaRegistration(response)),
        catchError((errorRes) => throwError(() => errorRes)),
      );
  }

  updateAdaRegistration(editParams: AdaRegistrationEditParams, adaRegistrationId: string): Observable<AdaRegistration> {
    return this.backendService
      .update<AdaRegistrationRaw>(`${environment.financialAccountFlow}/v1/debit-authorizations/${adaRegistrationId}`, editParams)
      .pipe(
        tap({
          next: (response) => {
            const { createdBy, updatedBy } = response;
            this.store.dispatch(AuditActions.loadTeamMembers({ ids: compact([createdBy, updatedBy]) }));
          },
        }),
        map((response) => this.mapAdaRegistration(response)),
        catchError((errorRes) => throwError(() => errorRes)),
      );
  }
}

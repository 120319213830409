import { Component } from '@angular/core';
import { ActiveModal } from '@rocketfinancialcorp/rocket-ui/modal';
import { DatatableColumn } from '@rocketfinancialcorp/rocket-ui/table';
import { ReportService } from '@shared/services';
import { MESSAGE } from '@shared/constants';
import { isEmpty } from 'lodash-es';

@Component({
  selector: 'app-natural-language-search-modal',
  moduleId: module.id,
  templateUrl: 'natural-language-search-modal.component.html',
  styleUrls: ['natural-language-search-modal.component.scss'],
})
export class NaturalLanguageSearchModalComponent {
  searchString?: string;

  displaySearchString?: string;

  searchErrorText!: string;

  downloadResultURL!: string | undefined;

  insightSummary!: string | undefined;

  searchResults: { [key: string]: number | string }[] = [];

  loading = false;

  columns: DatatableColumn[] = [];

  sampleQueryTexts: string[] = [
    'What is my monthly transaction volume and amount?',
    'How many of my transactions are currently pending?',
    'How many customers do I have?',
    'How many financial accounts do I have?',
  ];

  get totalElements(): number {
    return this.searchResults.length;
  }

  get downloadResultEnabled(): boolean {
    return !isEmpty(this.downloadResultURL);
  }

  constructor(public activeModal: ActiveModal, private reportService: ReportService) {}

  getAISearchResults() {
    if (!this.searchString) return;

    this.searchReset();

    this.loading = true;

    this.reportService.getAISearchResults(this.searchString).subscribe({
      next: (response) => {
        if (response?.results) {
          this.insightSummary = response?.summaryOfResults;
          this.searchResults = response.results;
          this.columns = Object.keys(this.searchResults[0]).map((key) => {
            return { name: key, prop: key, flexSize: 200, canAutoResize: true, minWidth: 100, maxWidth: 300 };
          });
          this.downloadResultURL = response.presignedUrlForCsvResults;
          this.displaySearchString = this.searchString;
        } else {
          this.searchErrorText = 'No search results. Try entering another search query.';
        }
        this.loading = false;
        this.searchString = undefined;
      },
      error: (error) => {
        console.warn('error', error);
        this.searchErrorText = MESSAGE.UNKNOWN_ERROR;
        this.loading = false;
      },
    });
  }

  searchReset() {
    this.searchErrorText = '';
    this.searchResults = [];
    this.columns = [];
    this.downloadResultURL = undefined;
    this.displaySearchString = undefined;
  }

  downloadCSVResult() {
    if (this.downloadResultURL) {
      window.open(this.downloadResultURL, '_self');
    }
  }

  updateSearchString(queryText: string) {
    this.searchString = queryText;
  }
}

import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { isEmpty } from 'lodash-es';
import { APP_ENV_CONFIG } from './app.config';

export const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
};

@Injectable({
  providedIn: 'root',
})
export class BackendService {
  envConfig = inject(APP_ENV_CONFIG);

  get baseUrl(): string {
    return this.envConfig.apiHost;
  }

  constructor(private http: HttpClient) {}

  get<T>(path: string, options = {}): Observable<T> {
    return this.http.get<T>(this.baseUrl + path, { headers: httpOptions.headers, ...options });
  }

  post<T>(path: string, body = {}, options = {}): Observable<T> {
    return this.http.post<T>(this.baseUrl + path, body, { headers: httpOptions.headers, ...options });
  }

  update<T>(path: string, body = {}, options = {}): Observable<T> {
    return this.http.put<T>(this.baseUrl + path, isEmpty(body) ? undefined : body, { ...httpOptions, ...options });
  }

  patch<T>(path: string, body = {}): Observable<T> {
    return this.http.patch<T>(this.baseUrl + path, body, httpOptions);
  }

  delete<T>(path: string, options = {}): Observable<T> {
    return this.http.delete<T>(this.baseUrl + path, { ...httpOptions, ...options });
  }

  upload<T>(path: string, body: string, options = {}): Observable<T> {
    return this.http.put<T>(path, body, { ...options });
  }
}

<div class="insight-page">
  <div class="header-container">
    <div class="header">
      <div class="title">Insights</div>
      <button rktButton title="Reports" class="insight-page-icon" btnType="link" (click)="viewReports()">
        <rkt-icon [name]="reportNotifications.length > 0 ? 'new-document' : 'new-document'" class="reports-icon"></rkt-icon>
        <div class="icon-title">Documents</div>
      </button>
    </div>

    <div class="menu-container">
      <div *ngFor="let menuOption of menuOptions" class="menu-option" (click)="menuOption.customClick && menuOption.customClick($event)">
        <div class="insight-menu-container">
          <button rktButton [title]="menuOption.icon" class="insight-page-icon" btnType="link">
            <rkt-icon [name]="menuOption.icon" class="menu-option-icon"></rkt-icon>
          </button>
          <div class="title">{{ menuOption.title }}</div>
          <div class="subtitle">{{ menuOption.subtitle }}</div>
        </div>
      </div>
    </div>
  </div>

  <div id="dashboard" class="embedded-superset"></div>

  <router-outlet></router-outlet>
</div>

<div class="document-search-container">
  <rkt-table-search
    (changed)="onSearch($event)"
    placeholder="Search..."
    class="document-search"
    [disabled]="loading"
    aria-autocomplete="none"
  ></rkt-table-search>
  <button class="add-scheduled-report" (click)="navigateToScheduledReport()" [disabled]="loading">
    <rkt-icon name="schedule" class="rocket-icon"></rkt-icon>
    <span>Schedule</span>
  </button>
</div>

<div class="document-list">
  <div class="report-form-container">
    <rkt-form [form]="reportFilterForm" [fields]="formFields" [model]="formModel" (modelChange)="onModelChange()"></rkt-form>
  </div>

  <ng-container *ngIf="filteredReports?.length; else onFetchDocuments">
    <div *ngFor="let documentList of filteredReports">
      <app-report-list-item [documentListItem]="documentList"></app-report-list-item>
    </div>

    <app-pagination [totalPages]="totalPages" [activePage]="activePage" (updatePageParam)="getInsightReports($event)"></app-pagination>
  </ng-container>

  <ng-template #onFetchDocuments>
    <div class="empty-state" *ngIf="!loading">
      <div class="image"></div>
      <div class="message">No Report Available</div>
    </div>

    <ng-container *ngIf="loading">
      <div *ngFor="let i of [].constructor(7)" class="document-list-item"></div>
    </ng-container>
  </ng-template>
</div>

import { inject, Injectable } from '@angular/core';
import { Observable, of, switchMap, throwError } from 'rxjs';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { environment } from '@env';
import {
  AddScheduledReportParams,
  AISearchResultsRaw,
  BusinessAccount,
  InsightDocumentList,
  InsightDocumentRaw,
  ReportExtractType,
  ReportLinkResponse,
  ReportList,
  ReportListRaw,
  ReportNotification,
  ReportTemplate,
  RequestPageParams,
  ScheduledReport,
  SupersetConfig,
} from '@shared/models';
import { constants } from '@shared/constants';
import { CustomHttpParamEncoder } from '@shared/encoder';
import { APP_ENV_CONFIG, BusinessAccountService, NotificationService } from '@shared/services';
import { BackendService } from '@shared/services/backend.service';
import { endOfMonth, startOfMonth } from 'date-fns';
import { isUndefined } from 'lodash-es';
import { reportRequestParams } from '@shared/services/report/report-mapping-utils';

@Injectable({
  providedIn: 'root',
})
export class ReportService {
  envConfig = inject(APP_ENV_CONFIG);

  constructor(
    private backendService: BackendService,
    private notificationService: NotificationService,
    private businessAccountService: BusinessAccountService,
  ) {}

  public getReports(
    activeBusinessAccountId: BusinessAccount['id'],
    { page, size, sortParams, activeFilters }: RequestPageParams,
  ): Observable<ReportList> {
    const pageSize = size ?? constants.TABLE_ROWS;
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() });

    params = params.append('size', pageSize);

    params = params.append('businessAccountId', activeBusinessAccountId);

    if (page) {
      params = params.append('page', page);
    }

    if (activeFilters) {
      const { extractType, yearFrom, monthFrom, yearTo, monthTo, financialAccountId } = activeFilters;
      if (extractType) {
        params = params.append('extractType', extractType);
      }

      if (financialAccountId) {
        params = params.append('financialAccountId', financialAccountId);
      }

      if (yearFrom && !isUndefined(monthFrom)) {
        params = params.append(
          'extractCreatedDateFrom',
          startOfMonth(new Date(parseInt(yearFrom, 10), parseInt(monthFrom, 10))).toISOString(),
        );
      }

      if (yearTo && !isUndefined(monthTo)) {
        params = params.append('extractCreatedDateTo', endOfMonth(new Date(parseInt(yearTo, 10), parseInt(monthTo, 10))).toISOString());
      }
    }

    if (sortParams) {
      params = params.append('sort', `${sortParams.key},${sortParams.sortDir}`);
    }

    return this.backendService.get<ReportListRaw>(`${environment.insightsEndpoint}/reports`, { params }).pipe(
      map((response) => {
        const { content = [], totalElements = 0 } = response || {};

        return {
          items: content?.map((item) => item),
          totalElements,
        };
      }),
      catchError((errorRes) => throwError(() => errorRes)),
    );
  }

  public getInsightReports(requestParams: RequestPageParams): Observable<InsightDocumentList> {
    let params = reportRequestParams(requestParams);

    return this.getSelectedBusinessAccountId().pipe(
      switchMap((businessAccountId) => {
        params = params.append('businessAccountId', businessAccountId);
        return this.backendService.get<InsightDocumentRaw>(`${environment.insightsEndpoint}/reports`, { params }).pipe(
          map((response) => {
            const { content = [], totalElements = 0, totalPages = 0 } = response || {};

            return {
              items: content?.map((item) => item),
              totalElements,
              totalPages,
            };
          }),
          catchError((errorRes) => throwError(() => errorRes)),
        );
      }),
    );
  }

  public getInsightReportPreSignedURL(reportId: string, fileType: string): Observable<ReportLinkResponse> {
    return this.backendService.get<ReportLinkResponse>(`${environment.insightsEndpoint}/reports/${reportId}/${fileType}`).pipe(
      map((response) => response),
      catchError((errorRes) => throwError(() => errorRes)),
    );
  }

  public getInsightReportTypes(): Observable<ReportExtractType[]> {
    return this.backendService.get<ReportExtractType[]>(`${environment.insightsEndpoint}/reports/types`).pipe(
      map((reportTypes) => {
        return reportTypes?.map((reportType) => ({
          ...reportType,
          value: reportType?.id,
        }));
      }),
      catchError((errorRes) => throwError(() => errorRes)),
    );
  }

  public getInsightReportStatus(): Observable<ReportNotification[]> {
    return this.backendService.get<ReportNotification[]>(`${environment.insightsEndpoint}/reports/latest-history`).pipe(
      map((response) => response),
      catchError((errorRes) => throwError(() => errorRes)),
    );
  }

  public getScheduledReports({ searchString }: RequestPageParams): Observable<ScheduledReport[]> {
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() });

    params = params.append('isInternal', false);

    if (searchString) {
      params = params.append('name', searchString);
    }
    return this.backendService.get<ScheduledReport[]>(`${environment.insightsEndpoint}/reports/schedulers`, { params }).pipe(
      map((response) => response),
      catchError((errorRes) => throwError(() => errorRes)),
    );
  }

  public getScheduledReportTemplates(): Observable<ReportTemplate[]> {
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() });

    params = params.append('isInternal', false);

    return this.backendService.get<ReportTemplate[]>(`${environment.insightsEndpoint}/reports/templates`, { params }).pipe(
      map((reportTemplates) => {
        return reportTemplates?.map((reportTemplate) => ({
          ...reportTemplate,
          label: reportTemplate.name,
          value: reportTemplate?.id,
        }));
      }),
      catchError((errorRes) => throwError(() => errorRes)),
    );
  }

  public createScheduledReport(scheduledReportData: AddScheduledReportParams): Observable<string> {
    return this.backendService.post<string>(`${environment.insightsEndpoint}/reports/schedulers`, scheduledReportData).pipe(
      map((response) => response),
      catchError((errorRes) => throwError(() => errorRes)),
    );
  }

  public updateScheduledReport(scheduledReportId: string, reportData: Partial<ScheduledReport>): Observable<boolean> {
    return this.backendService.patch(`${environment.insightsEndpoint}/reports/schedulers/${scheduledReportId}`, reportData).pipe(
      map(() => true),
      catchError((errorRes) => throwError(() => errorRes)),
    );
  }

  public deleteScheduledReport(scheduledReportId: string): Observable<boolean> {
    return this.backendService.delete<void>(`${environment.insightsEndpoint}/reports/schedulers/${scheduledReportId}`).pipe(
      map(() => true),
      catchError((errorRes) => throwError(() => errorRes)),
    );
  }

  public getInsightStatements(requestParams: RequestPageParams): Observable<InsightDocumentList> {
    const params = reportRequestParams(requestParams);

    return this.backendService.get<InsightDocumentRaw>(`${environment.insightsEndpoint}/reports/statements`, { params }).pipe(
      map((response) => {
        const { content = [], totalElements = 0, totalPages = 0 } = response || {};

        return {
          items: content?.map((item) => item),
          totalElements,
          totalPages,
        };
      }),
      catchError((errorRes) => throwError(() => errorRes)),
    );
  }

  public getAISearchResults(search?: string): Observable<AISearchResultsRaw> {
    if (!search) {
      return of({});
    }
    return this.backendService.post<AISearchResultsRaw>(`${environment.insightsEndpoint}/english-to-insights`, { query: search }).pipe(
      map((response) => response),
      catchError((errorRes) => throwError(() => errorRes)),
    );
  }

  public supersetInitConfig(): Observable<SupersetConfig> {
    const headers = new HttpHeaders().set('Content-Type', `application/x-www-form-urlencoded`);
    const urlencoded = new URLSearchParams();
    urlencoded.append('dashboardName', 'Insights Dashboard');

    return this.backendService.post<SupersetConfig>(`${environment.insightsEndpoint}/superset`, urlencoded, { headers }).pipe(
      map((response) => response),
      catchError((errorRes) => throwError(() => errorRes)),
    );
  }

  private getSelectedBusinessAccountId(): Observable<BusinessAccount['id']> {
    return this.businessAccountService.getSelectedBusinessAccountId();
  }
}

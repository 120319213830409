<div class="template-wrapper">
  <div class="report-config">
    <div class="title">
      <div>Schedule Report</div>
      <button class="close-button" (click)="activeModal.close()">
        <rkt-icon name="times"></rkt-icon>
      </button>
    </div>
    <div class="report-config-form">
      <div class="form-details">
        <div class="section-title">
          <div class="section-title-text">Report Type</div>
        </div>

        <div class="section-content">
          <ng-container *ngIf="reportTemplates?.length; else onFetchTemplates">
            <div
              *ngFor="let reportTemplate of reportTemplates"
              class="report-template-list-item"
              (click)="setSelectedTemplate(reportTemplate)"
              [class.active]="selectedTemplate === reportTemplate"
            >
              {{ reportTemplate.name }}
            </div>
          </ng-container>

          <ng-template #onFetchTemplates>
            <div class="empty-state" *ngIf="!isLoading">
              <div class="image"></div>
              <div class="message">No Template Available</div>
            </div>

            <ng-container *ngIf="isLoading">
              <div class="section-content">
                <div *ngFor="let i of [].constructor(3)" class="report-template-list-item"></div>
              </div>
            </ng-container>
          </ng-template>
        </div>

        <div class="section-title">
          <div class="section-title-text">Scheduling</div>
        </div>

        <div class="section-content">
          <form [formGroup]="createScheduleReportForm">
            <rkt-form [form]="createScheduleReportForm" [fields]="reportFormFields" [model]="addReportModel"></rkt-form>
          </form>
        </div>
      </div>

      <div class="form-footer">
        <div class="details-page-edit-form-error">{{ submitError }}</div>

        <div class="buttons">
          <button
            rktButton
            type="submit"
            (click)="onSave()"
            [disabled]="isLoading || createScheduleReportForm.invalid || !selectedTemplate"
          >
            Schedule
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="report-preview">
    <ng-container *ngIf="selectedTemplate?.previewSignedUrl; else noPreviewAvailable">
      <app-view-pdf-document
        [documentURL]="selectedTemplate.previewSignedUrl"
        [containerHeight]="'41.5rem'"
        [showDownloadButton]="false"
        [showPrintButton]="false"
      ></app-view-pdf-document>
    </ng-container>

    <ng-template #noPreviewAvailable>
      <div class="empty-state">
        <div class="image"></div>
        <div class="message">No preview available</div>
      </div>
    </ng-template>
  </div>
</div>

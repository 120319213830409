import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-document-page',
  templateUrl: './document-page.component.html',
  styleUrls: ['./document-page.component.scss'],
})
export class DocumentPageComponent {
  get isExcludedPage() {
    return this.router.url.match('/app/insights/documents/(scheduled|find)-report/*');
  }

  constructor(private router: Router) {}

  tabItems = [
    { label: 'Statements', href: '/app/insights/documents/statements' },
    { label: 'Reports', href: '/app/insights/documents/reports' },
  ];

  closeSideMenu() {
    this.router.navigate(['/app/insights']);
  }

  onSearchReports() {
    this.router.navigate(['/app/insights/documents/find-report']);
  }
}
